import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './assets/locales/en/translation.json';
import translationBa from './assets/locales/ba/translation.json';
import translationHR from './assets/locales/hr/translation.json';
import translationRS from './assets/locales/rs/translation.json';

const fallbackLng = ['en'];
const availableLanguages = ['ba', 'en', 'hr', 'rs'];

const resources = {
  ba: {
    translation: translationBa,
  },
  en: {
    translation: translationEN,
  },
  hr: {
    translation: translationHR,
  },
  rs: {
    translation: translationRS,
  },
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng,

    detection: {
      checkWhitelist: true,
    },

    debug: false,

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
