import React from 'react';
import PropTypes from 'prop-types';
import './Map.css';
import { Marker, Popup } from 'react-leaflet';
import * as L from 'leaflet';
import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png';
import iconUrl from 'leaflet/dist/images/marker-icon.png';
import shadowUrl from 'leaflet/dist/images/marker-shadow.png';
import { SELECTED_POLLUTANT } from '../../utils/enums';
import PollutantList from './PollutantList';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: iconRetinaUrl,
  iconUrl: iconUrl,
  shadowUrl: shadowUrl,
  iconSize: [20, 30],
});

const StationCircleMarker = ({ station, selectedPollutant, config }) => {
  const { t } = useTranslation();

  if (!station || !station.pollutants || station.pollutants.length === 0) {
    return null;
  }

  let pollutant = null;
  if (selectedPollutant !== SELECTED_POLLUTANT.AQI) {
    const p = station.pollutants.filter(
      (x) => x.pollutantnotation === selectedPollutant
    )[0];
    pollutant = p;
  }

  const renderMarkerIcon = () => {
    return selectedPollutant === SELECTED_POLLUTANT.AQI
      ? L.divIcon({
          html: `<div class="txt" style="background-color: ${
            station.maxPollutant.meta.color
          }">${station.maxPollutant.meta.level.toString()}</div>`,
          className: `circle-with-txt ${station.maxPollutant.meta.text
            .substring(0, 4)
            .toLowerCase()}`,
          iconSize: [30, 30],
        })
      : L.divIcon({
          html: `<div class="txt">${pollutant.meta.level}</div>`,
          className: `circle-with-txt ${pollutant.meta.text
            .substring(0, 4)
            .toLowerCase()}`,
          iconSize: [30, 30],
        });
  };

  return (
    <Marker
      position={[station.latitude, station.longitude]}
      fillOpacity={1}
      icon={renderMarkerIcon()}
    >
      <Popup className="request-popup">
        <div
          className="topbar"
          style={{
            backgroundColor:
              selectedPollutant === SELECTED_POLLUTANT.AQI
                ? station.maxPollutant.meta.color
                : pollutant.meta.color,
          }}
        ></div>
        <h2>{station.stationname}</h2>
        <div className="row">
          <div className="column1 bold">{t('station-facts-date')}</div>
          <div className="column1">
            {moment
              .utc(station.pollutants[0].latesttimestep)
              .tz(config.timezone)
              .format(config.dateFormat)}
          </div>
        </div>
        <div className="row">
          <div className="column1 bold">{t('station-facts-location')}</div>
          <div className="column1">{`${station.stationname} , ${t(
            station.country
          )}`}</div>
        </div>
        <div className="row">
          <div className="column1 bold">
            {t('station-facts-classification')}
          </div>
          <div className="column1">{t(station.classification)}</div>
        </div>
        <div className="row">
          <div className="column1 bold">{t('station-facts-area')}</div>
          <div className="column1">{t(station.area)}</div>
        </div>
        <div className="row">
          <div className="column1 bold">{t('station-facts-ra')}</div>
          <div className="column1">{station.responsibleauthority}</div>
        </div>
        <h3>{`${
          selectedPollutant === SELECTED_POLLUTANT.AQI
            ? t('station-facts-mp')
            : t('station-facts-sp')
        }`}</h3>
        <PollutantList
          station={station}
          selectedPollutant={selectedPollutant}
          pollutant={pollutant}
        />
      </Popup>
    </Marker>
  );
};

StationCircleMarker.propTypes = {
  station: PropTypes.object,
  selectedPollutant: PropTypes.string,
  config: PropTypes.object,
};

export default StationCircleMarker;
