import * as L from 'leaflet';

export const getMapOptions = (config) => {
  const position = config.position;
  const zoom = config.zoom;
  return {
    center: position,
    zoom,
    minZoom: 4,
    attributionControl: false,
    crs: L.CRS.EPSG900913,
  };
};

export const BACKGROUND_LAYER_OPTIONS = {
  attribution:
    '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
  url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
};
