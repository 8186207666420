import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const ContactItem = ({ contact }) => {
  const { t } = useTranslation();
  return Object.keys(contact).map((c, i) => {
    return (
      <>
        <div className="flex" key={`c${i}`}>
          <p className="bold upper">{`${t(c)}:`}</p>
          {c === 'website' && (
            <a className="link" href={contact[c]}>
              {contact[c]}
            </a>
          )}
          {c !== 'website' && <p>{contact[c]}</p>}
        </div>
      </>
    );
  });
};

ContactItem.propTypes = {
  contact: PropTypes.object,
};

export default ContactItem;
