import React, { useState } from 'react';
import Control from 'react-leaflet-control';
import 'react-dropdown/style.css';
import Button from '@material-ui/core/Button';
import { materialButtonStyle } from '../../utils/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import MenuItem from '../AirMap/SideMenu/MenuItem';
import '../AirMap/SideMenu/SideMenu.css';
import FocusTrap from 'focus-trap-react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import LanguageIcon from '@material-ui/icons/Language';

const LanguageSelector = ({ config }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const languages = Object.keys(config.languages);

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <Control className="raven-side-menu" position="topright">
        <div style={{ display: 'flex' }}>
          <Button
            variant="contained"
            color="primary"
            title="Meny"
            onClick={toggleOpen}
            style={{
              ...materialButtonStyle,
            }}
          >
            <LanguageIcon className="menu-button-icon" />
            {t('menu-language')}
            {!open ? (
              <ArrowDropDownIcon className="menu-button-icon" />
            ) : (
              <ArrowDropUpIcon className="menu-button-icon" />
            )}
          </Button>
          <Button
            className="not-allowed"
            variant="contained"
            color="primary"
            title="Meny"
            style={{
              borderLeft: '1px solid #006cc0',
              ...materialButtonStyle,
            }}
          >
            {t(config.languages[i18next.language].label)}
          </Button>
        </div>
        {open && (
          <>
            <FocusTrap
              focusTrapOptions={{
                clickOutsideDeactivates: true,
                allowOutsideClick: true,
              }}
            >
              <div className="side-menu--list">
                {languages.map((language, i) => {
                  return (
                    <MenuItem
                      key={i}
                      divider={languages.length === i + 1}
                      text={config.languages[language].label}
                      style={{ backgroundColor: 'white' }}
                      onClick={() => {
                        i18next.changeLanguage(language);
                        setOpen(false);
                      }}
                      selected={i18next.language === language}
                    />
                  );
                })}
              </div>
            </FocusTrap>
          </>
        )}
      </Control>
    </>
  );
};

LanguageSelector.propTypes = {
  config: PropTypes.object,
};

export default LanguageSelector;
