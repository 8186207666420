import React, { useState, useEffect, useRef } from 'react';
import Error from '../Error';
import Loading from '../Loading';
import StationCircleMarker from './StationCircleMarker';
import PollutantSelector from './PollutantSelector';
import LanguageSelector from './LanguageSelector';
import './Map.css';
import { Map, TileLayer, ZoomControl } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { BACKGROUND_LAYER_OPTIONS, getMapOptions } from './mapUtils';
import { fetchStations } from '../../api/stations';
import { getPollutantInfo, sortPollutants } from '../../utils/funcs';
import SideMenu from './SideMenu';
import Legend from './Legend';
import LegendDetails from './Legend/LegendDetails';
import { useIsMobile } from '../../hooks/useIsMobile';
import Control from 'react-leaflet-control';
import AccessibleDrawer from '../../components/AccessibleDrawer';
import i18next from 'i18next';
import configuration from '../../config.json';

export const AirMap = () => {
  const map = useRef(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [stations, setStations] = useState(null);
  const [selectedPollutant, setSelectedPollutant] = useState('AQI');
  const [open, setOpen] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const isMobile = useIsMobile();

  useEffect(() => {
    const initData = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetchStations();
        if (!response || response.error) {
          setError('Fetching data failed...');
          setLoading(false);
        }
        if (response && response[0]) {
          if (!configuration[response[0].country]) {
            setError('No configuration found...');
          } else {
            i18next.changeLanguage(
              configuration[response[0].country].defaultLanguage
            );
            response.forEach((station) => {
              if (
                station &&
                station.pollutants &&
                station.pollutants.length > 0
              ) {
                station.pollutants.forEach((pollutant) => {
                  pollutant.meta = getPollutantInfo(
                    pollutant,
                    configuration[response[0].country]
                  );
                });
                const levels = station.pollutants.map((x) => x.meta.level);
                const maxlevel = Math.max.apply(Math, levels);
                station.maxPollutant = station.pollutants.filter(
                  (x) => x.meta.level === maxlevel
                )[0];
                sortPollutants(station);
              }
            });
            setStations(response);
            setLoading(false);
          }
        }
      } catch (e) {
        setLoading(false);
        setError('Fetching data failed...');
      }
    };
    initData();
  }, []);
  return (
    <>
      <div id="raven-map">
        <Loading isLoading={loading} text="Loading data..." />
        <Error error={error} />
        {!error && !loading && stations && (
          <>
            <Map
              className="map"
              {...getMapOptions(configuration[stations[0].country])}
              ref={map}
              zoomControl={false}
            >
              <ZoomControl position="bottomright" />
              <SideMenu
                open={open}
                setOpen={setOpen}
                setSelectedPollutant={setSelectedPollutant}
                selectedPollutant={selectedPollutant}
                config={configuration[stations[0].country]}
              />
              {!isMobile && (
                <>
                  <PollutantSelector
                    setSelectedPollutant={setSelectedPollutant}
                    selectedPollutant={selectedPollutant}
                  />
                  {configuration[stations[0].country] && (
                    <LanguageSelector
                      config={configuration[stations[0].country]}
                    />
                  )}
                </>
              )}
              <Legend
                setOpenDetails={setOpenDetails}
                openDetails={openDetails}
                config={configuration[stations[0].country]}
              />
              {isMobile && openDetails && configuration[stations[0].country] && (
                <Control position="topleft">
                  <AccessibleDrawer
                    open={openDetails}
                    onClose={() => setOpenDetails(false)}
                    level={null}
                    handler={false}
                    placement="left"
                    showMask={false}
                  >
                    {configuration[stations[0].country] && (
                      <LegendDetails
                        toggleOpen={() => setOpenDetails(false)}
                        config={configuration[stations[0].country]}
                      />
                    )}
                  </AccessibleDrawer>
                </Control>
              )}
              <TileLayer {...BACKGROUND_LAYER_OPTIONS} />
              {stations &&
                stations.length > 0 &&
                stations.map((station, i) => {
                  return (
                    <div key={`station${i}`}>
                      {station.pollutants &&
                        station.pollutants.some(
                          (x) =>
                            x.pollutantnotation === selectedPollutant ||
                            selectedPollutant === 'AQI'
                        ) && (
                          <StationCircleMarker
                            station={station}
                            selectedPollutant={selectedPollutant}
                            config={configuration[stations[0].country]}
                          />
                        )}
                    </div>
                  );
                })}
            </Map>
          </>
        )}
      </div>
    </>
  );
};

export default AirMap;
