import React, { useContext } from 'react';
import 'react-dropdown/style.css';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import './ViewContainer.css';
import { materialButtonStyle } from '../../utils/styles';
import AppContext from '../AppContext';
import PropTypes from 'prop-types';

const ViewContainer = ({ header, children }) => {
  const globalContext = useContext(AppContext);
  return (
    <div id="raven-view-container">
      <div className="header">
        <h2> {header}</h2>
        <Button
          className="close"
          color="primary"
          onClick={() => {
            globalContext.setView('map');
          }}
          style={{ ...materialButtonStyle }}
        >
          <CloseIcon className="menu-button-icon" />
        </Button>
      </div>
      {children}
    </div>
  );
};

ViewContainer.propTypes = {
  header: PropTypes.string,
  children: PropTypes.any,
};

export default ViewContainer;
