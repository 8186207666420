import React, { useState } from 'react';
import { AirMap } from '../AirMap';
import AppContext from '../AppContext';
import { useTranslation } from 'react-i18next';
import ViewContainer from '../ViewContainer';
import Contact from '../Contact';

const App = () => {
  const [view, setView] = useState('map');
  const { t } = useTranslation();

  const globalstate = {
    viewname: view,
    setView,
  };
  return (
    <AppContext.Provider value={globalstate}>
      <div id="raven-app">
        {view === 'con' && (
          <ViewContainer header={t('menu-contact')}>
            <Contact />
          </ViewContainer>
        )}
        {view === 'map' && <AirMap />}
      </div>
    </AppContext.Provider>
  );
};

export default App;
