const ERROR_MESSAGE = 'Error when fetching contacts.';

const END_POINT = '/api/gui/contacts';

export const fetchContacts = async () => {
  try {
    const response = await fetch(`${END_POINT}`);

    const json = await response.json();
    if (!response.ok) {
      return {
        error: ERROR_MESSAGE,
      };
    }
    return json;
  } catch (e) {
    return { error: ERROR_MESSAGE };
  }
};
