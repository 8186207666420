export const SELECTED_POLLUTANT = {
  AQI: 'AQI',
  NO2: 'NO2',
  PM10: 'PM10',
  'PM2.5': 'PM2.5',
  O3: 'O3',
  SO2: 'SO2',
};

export const LEGEND_INFO = {
  Good: { backgroundColor: '#00FF00', color: 'black' },
  Fair: { backgroundColor: '#FFFF00', color: 'white' },
  Moderate: { backgroundColor: '#FFA500', color: 'black' },
  Poor: { backgroundColor: '#FF0000', color: 'white' },
  'Very poor': { backgroundColor: '#800080', color: 'white' },
  'Extremley poor': { backgroundColor: '#800000', color: 'white' },
};

export const LANGUAGE_MAP = {
  en: { label: 'menu-english', dir: 'ltr', active: true },
  ba: { label: 'menu-bosnian', dir: 'ltr', active: false },
  hr: { label: 'menu-croatian', dir: 'ltr', active: false },
  rs: { label: 'menu-serbian', dir: 'ltr', active: false },
};
