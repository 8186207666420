import React, { useRef } from 'react';
import 'react-dropdown/style.css';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import '../Map.css';
import { LEGEND_INFO } from '../../../utils/enums';
import { materialButtonStyle } from '../../../utils/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useIsMobile } from '../../../hooks/useIsMobile';

const LegendDetails = ({ toggleOpen, config }) => {
  const buttonRef = useRef();
  const { t } = useTranslation();
  const legendInfo = Object.keys(LEGEND_INFO);
  const isMobile = useIsMobile();
  const o3 = { ...config.aqi.o3 };
  const pm10 = { ...config.aqi.pm10 };
  const so2 = { ...config.aqi.so2 };
  const pm25 = { ...config.aqi.pm25 };
  const no2 = { ...config.aqi.no2 };

  const LEGEND_DETAILS_INFO = {
    'PM2.5': {
      name: t('legend-details-PM2'),
      levels: [
        `0-${pm25.good.max}`,
        `${pm25.fair.min}-${pm25.fair.max}`,
        `${pm25.moderate.min}-${pm25.moderate.max}`,
        `${pm25.poor.min}-${pm25.poor.max}`,
        `${pm25.veryPoor.min}-${pm25.veryPoor.max}`,
        `${pm25.extremelyPoor.min} <`,
      ],
    },
    NO2: {
      name: t('legend-details-NO2'),
      levels: [
        `0-${no2.good.max}`,
        `${no2.fair.min}-${no2.fair.max}`,
        `${no2.moderate.min}-${no2.moderate.max}`,
        `${no2.poor.min}-${no2.poor.max}`,
        `${no2.veryPoor.min}-${no2.veryPoor.max}`,
        `${no2.extremelyPoor.min} <`,
      ],
    },
    PM10: {
      name: t('legend-details-PM10'),
      levels: [
        `0-${pm10.good.max}`,
        `${pm10.fair.min}-${pm10.fair.max}`,
        `${pm10.moderate.min}-${pm10.moderate.max}`,
        `${pm10.poor.min}-${pm10.poor.max}`,
        `${pm10.veryPoor.min}-${pm10.veryPoor.max}`,
        `${pm10.extremelyPoor.min} <`,
      ],
    },
    O3: {
      name: t('legend-details-O3'),
      levels: [
        `0-${o3.good.max}`,
        `${o3.fair.min}-${o3.fair.max}`,
        `${o3.moderate.min}-${o3.moderate.max}`,
        `${o3.poor.min}-${o3.poor.max}`,
        `${o3.veryPoor.min}-${o3.veryPoor.max}`,
        `${o3.extremelyPoor.min} <`,
      ],
    },
    SO2: {
      name: t('legend-details-So2'),
      levels: [
        `0-${so2.good.max}`,
        `${so2.fair.min}-${so2.fair.max}`,
        `${so2.moderate.min}-${so2.moderate.max}`,
        `${so2.poor.min}-${so2.poor.max}`,
        `${so2.veryPoor.min}-${so2.veryPoor.max}`,
        `${so2.extremelyPoor.min} <`,
      ],
    },
  };

  const legendInfoDetails = Object.keys(LEGEND_DETAILS_INFO);

  return (
    <div className="legend-details-container">
      <div className="details-header">
        {isMobile && (
          <Button
            className="close"
            ref={buttonRef}
            color="primary"
            onClick={toggleOpen}
            style={{ ...materialButtonStyle }}
          >
            <CloseIcon className="menu-button-icon" />
          </Button>
        )}
        <h2> {t('legend-details-header')}</h2>
        {!isMobile && (
          <Button
            className="close"
            ref={buttonRef}
            color="primary"
            onClick={toggleOpen}
            style={{ ...materialButtonStyle }}
          >
            <CloseIcon className="menu-button-icon" />
          </Button>
        )}
      </div>
      <hr />
      <div className="content">
        <p>
          {t('legend-details-part1')}
          <br />
          <br />
          {t('legend-details-part2')}
        </p>
        <div className="legend-detail-row">
          <div className="head">
            <h3>{t('legend-details-pollutant')}</h3>
          </div>
          {legendInfo.map((text, legendInfoIndex) => {
            return (
              <div key={`header ${legendInfoIndex}`} className="value">
                {t(text)}
              </div>
            );
          })}
        </div>
        <div>
          {legendInfoDetails.map((legendInfoDetail, detailIndex) => {
            return (
              <div className="legend-detail-row" key={`legend ${detailIndex}`}>
                <div className="head">{`${LEGEND_DETAILS_INFO[legendInfoDetail].name}`}</div>
                {LEGEND_DETAILS_INFO[legendInfoDetail].levels.map(
                  (level, levelIndex) => {
                    return (
                      <div
                        style={{
                          backgroundColor:
                            LEGEND_INFO[legendInfo[levelIndex]].backgroundColor,
                          color: LEGEND_INFO[legendInfo[levelIndex]].color,
                        }}
                        className="value"
                        key={`${detailIndex}${levelIndex}`}
                      >
                        {level}
                      </div>
                    );
                  }
                )}
              </div>
            );
          })}
        </div>
        <div className="legend-detail-row">
          <div className="head"> </div>
          {legendInfo.map((text, legendInfoIndex) => {
            return (
              <div key={`header ${legendInfoIndex}`} className="value">
                {legendInfoIndex + 1}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

LegendDetails.propTypes = {
  toggleOpen: PropTypes.func,
  config: PropTypes.object,
};

export default LegendDetails;
