import React, { useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import Control from 'react-leaflet-control';
import 'react-dropdown/style.css';
import AccessibleDrawer from '../../AccessibleDrawer';
import MenuItem from './MenuItem';
import './SideMenu.css';
import { SELECTED_POLLUTANT, LANGUAGE_MAP } from '../../../utils/enums';
import { materialButtonStyle } from '../../../utils/styles';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/core/styles';
import i18next from 'i18next';
import AppContext from '../../AppContext';
import { useTranslation } from 'react-i18next';
import { getPollutantDisplay } from '../../../utils/funcs';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

const SideMenu = ({
  open,
  setOpen,
  setSelectedPollutant,
  selectedPollutant,
  config,
}) => {
  const globalContext = useContext(AppContext);
  const { t } = useTranslation();
  const classes = useStyles();

  const buttonRef = useRef();

  const closePanel = () => {
    setOpen(false);
  };

  const toggleOpen = () => {
    setOpen(!open);
  };

  const pollutants = Object.keys(SELECTED_POLLUTANT);

  return (
    <>
      <Control position="topleft">
        <Button
          variant="contained"
          color="primary"
          title="Meny"
          onClick={toggleOpen}
          style={{
            ...materialButtonStyle,
          }}
          ref={buttonRef}
        >
          <MenuIcon className="menu-button-icon" /> {t('menu-header')}
        </Button>
      </Control>
      <AccessibleDrawer
        open={open}
        backwardFocusRef={buttonRef}
        onClose={closePanel}
        level={null}
        handler={false}
        placement="left"
        showMask={false}
        className="raven-side-menu"
      >
        <div className="side-menu--header">
          <h1>{t('menu-header')}</h1>
          <div className={classes.root}>
            <Button color="primary" onClick={closePanel}>
              <ArrowBackIcon className="back-button-icon" />
            </Button>
          </div>
        </div>
        <div className="side-menu--list">
          <MenuItem text={t('menu-show')} disabled />
          {pollutants.map((pollutant, i) => {
            return (
              <MenuItem
                key={i}
                divider={pollutants.length === i + 1}
                text={getPollutantDisplay(
                  pollutant === 'AQI' ? t('aqi') : pollutant
                )}
                onClick={() => {
                  setSelectedPollutant(pollutant);
                  closePanel();
                  buttonRef.current.focus();
                }}
                selected={pollutant === selectedPollutant}
                indent
              />
            );
          })}
          <MenuItem
            text={t('menu-contact')}
            onClick={() => {
              globalContext.setView('con');
            }}
          />
          <MenuItem text={t('menu-language')} disabled />
          {Object.keys(LANGUAGE_MAP)?.map((item, i) => (
            <MenuItem
              text={t(config.languages[item].label)}
              key={`language-${i}`}
              onClick={() => {
                i18next.changeLanguage(item);
                closePanel();
              }}
              selected={i18next.language === item}
              indent
            />
          ))}
        </div>
      </AccessibleDrawer>
    </>
  );
};

SideMenu.propTypes = {
  setOpen: PropTypes.func,
  open: PropTypes.bool,
  setSelectedPollutant: PropTypes.func,
  selectedPollutant: PropTypes.string,
  config: PropTypes.object,
};

export default SideMenu;
