import React from 'react';
import PropTypes from 'prop-types';
import './SideMenu.css';

const MenuItem = ({
  text,
  onClick,
  onKeyPress,
  disabled = false,
  divider = false,
  selected = false,
  indent = false,
}) => {
  return (
    <button
      tabIndex={disabled ? -1 : 0}
      aria-current={selected}
      className={`side-menu--item
      ${divider ? 'divider' : ''}
      ${disabled ? 'disabled' : ''}
      ${selected ? 'selected' : ''}`}
      onClick={onClick}
      onKeyPress={onKeyPress}
      style={{ width: '100%' }}
    >
      <span
        className="menu-item--text"
        style={{ paddingLeft: indent ? '1rem' : '0', textAlign: 'left' }}
      >
        {text}
      </span>
    </button>
  );
};

MenuItem.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  onKeyPress: PropTypes.func,
  disabled: PropTypes.bool,
  divider: PropTypes.bool,
  selected: PropTypes.bool,
  indent: PropTypes.bool,
};

export default MenuItem;
