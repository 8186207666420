export const materialButtonStyle = {
  padding: '1rem',
  borderRadius: '0.125rem',
  textTransform: 'capitalize',
  fontSize: '1.125rem',
  lineHeight: '1.25rem',
  backgroundColor: 'white',
  boxShadow: 'none',
  color: '#006cc0',
};

export const materialTextButtonStyle = {
  margin: '0',
  borderRadius: '0.125rem',
  textTransform: 'capitalize',
  textDecoration: 'underline',
};
