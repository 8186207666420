import React, { useState, useEffect } from 'react';
import Drawer from 'rc-drawer';
import FocusTrap from 'focus-trap-react';
import PropTypes from 'prop-types';
import 'rc-drawer/assets/index.css';
import './AccessibleDrawer.css';

const AccessibleDrawer = ({
  open,
  backwardFocusRef,
  focusTrapProps,
  children,
  ...drawerProps
}) => {
  const [renderContent, setRenderContent] = useState(open);

  useEffect(() => {
    setRenderContent(!!open);
  }, [open]);

  return (
    <Drawer open={open} {...drawerProps}>
      {renderContent && (
        <FocusTrap
          focusTrapOptions={{
            clickOutsideDeactivates: true,
            allowOutsideClick: true,
          }}
          {...focusTrapProps}
        >
          <div className="accessible-drawer--content">{children}</div>
        </FocusTrap>
      )}
    </Drawer>
  );
};

AccessibleDrawer.propTypes = {
  open: PropTypes.bool,
  backwardFocusRef: PropTypes.any,
  focusTrapProps: PropTypes.any,
  children: PropTypes.any,
};

export default AccessibleDrawer;
