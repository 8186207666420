import React from 'react';
import PropTypes from 'prop-types';
import 'react-dropdown/style.css';
import { SELECTED_POLLUTANT } from '../../utils/enums';
import { useTranslation } from 'react-i18next';
import { getPollutantDisplay } from '../../utils/funcs';

const PollutantList = ({ station, selectedPollutant, pollutant }) => {
  const { t } = useTranslation();
  const isAqi = selectedPollutant === SELECTED_POLLUTANT.AQI;

  const PollutantItem = ({ pollutant, rowKey }) => {
    return pollutant.pollutantvalue ? (
      <div key={rowKey} className="row">
        <div className="column1">{`${getPollutantDisplay(
          pollutant.pollutantnotation
        )}`}</div>
        <div className="column1">{`${
          pollutant.aqipollutantvalue
            ? Math.round(pollutant.aqipollutantvalue)
            : Math.round(pollutant.pollutantvalue)
        }`}</div>
        <div className="column1">
          <div
            className={`square-box ${pollutant.meta.text
              .substring(0, 4)
              .toLowerCase()}`}
          >
            <div className="square-content">
              <div>
                <span
                  className={`${pollutant.meta.text
                    .substring(0, 4)
                    .toLowerCase()}`}
                >{`${pollutant.meta.level}`}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="column2">{t(pollutant.meta.text)}</div>
      </div>
    ) : (
      <div> </div>
    );
  };

  PollutantItem.propTypes = {
    rowKey: PropTypes.string,
  };

  return isAqi ? (
    station.pollutants.map((pollutant, i) => {
      return (
        <PollutantItem
          pollutant={pollutant}
          key={`pollutant ${i.toString()}`}
          rowKey={`pollutant ${i.toString()}`}
        />
      );
    })
  ) : (
    <PollutantItem pollutant={pollutant} rowKey="1" />
  );
};

PollutantList.propTypes = {
  station: PropTypes.object,
  selectedPollutant: PropTypes.string,
  pollutant: PropTypes.object,
};

export default PollutantList;
