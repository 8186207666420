import React from 'react';
import PropTypes from 'prop-types';
import './Error.css';

const Error = ({ error }) => {
  if (!error) {
    return null;
  }

  return (
    <div id="raven-error-message-container ">
      <h4>{error}</h4>
    </div>
  );
};

Error.propTypes = {
  error: PropTypes.string,
  setError: PropTypes.func,
};

export default Error;
