import React, { useState, useRef, useEffect } from 'react';
import Control from 'react-leaflet-control';
import 'react-dropdown/style.css';
import Button from '@material-ui/core/Button';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { makeStyles } from '@material-ui/core/styles';
import '../Map.css';
import { LEGEND_INFO } from '../../../utils/enums';
import { materialTextButtonStyle } from '../../../utils/styles';
import { materialButtonStyle } from '../../../utils/styles';
import LegendDetails from './LegendDetails';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useIsMobile } from '../../../hooks/useIsMobile';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

const Legend = ({ setOpenDetails, openDetails, config }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const buttonRef = useRef();
  const mapButtonRef = useRef();
  const [open, setOpen] = useState(false);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (open && buttonRef && buttonRef.current && open) {
      buttonRef.current.focus();
    }
    if (mapButtonRef && mapButtonRef.current && !open) {
      mapButtonRef.current.focus();
    }
  }, [open]);

  const toggleOpen = () => {
    setOpen(!open);
    setOpenDetails(false);
    if (buttonRef && buttonRef.current && open) {
      buttonRef.current.focus();
    }
    if (mapButtonRef && mapButtonRef.current && !open) {
      mapButtonRef.current.focus();
    }
  };

  const toggleOpenDetails = () => {
    setOpenDetails(!openDetails);
  };

  const legendItems = Object.keys(LEGEND_INFO);

  return (
    <>
      <Control position="bottomleft" className="request-popup">
        {open && (
          <>
            <div className="legend-container">
              {legendItems.map((item, i) => {
                return (
                  <div className="row" key={`legend ${i}`}>
                    <div className="column1">
                      <div
                        className={`square-box ${item
                          .substring(0, 4)
                          .toLowerCase()}`}
                      >
                        <div className="square-content">
                          <div>
                            <span
                              className={`${item
                                .substring(0, 4)
                                .toLowerCase()}`}
                            >{`${i + 1}`}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="column2">{`${t(item)}`}</div>
                  </div>
                );
              })}
              <div className={classes.root} style={{ textAlign: 'right' }}>
                <Button
                  ref={buttonRef}
                  color="primary"
                  onClick={toggleOpenDetails}
                  style={{ ...materialTextButtonStyle }}
                >
                  {`${
                    openDetails
                      ? t('legend-close-details')
                      : t('legend-open-details')
                  }`}
                </Button>
              </div>
            </div>
          </>
        )}
        <Button
          ref={mapButtonRef}
          variant="contained"
          color="primary"
          title="Meny"
          onClick={toggleOpen}
          style={{
            ...materialButtonStyle,
          }}
        >
          {t('legend-label')}
          {open ? (
            <ArrowDropDownIcon className="menu-button-icon" />
          ) : (
            <ArrowDropUpIcon className="menu-button-icon" />
          )}
        </Button>
        {openDetails && !isMobile && (
          <LegendDetails toggleOpen={toggleOpenDetails} config={config} />
        )}
      </Control>
    </>
  );
};

Legend.propTypes = {
  setOpenDetails: PropTypes.func,
  openDetails: PropTypes.bool,
  config: PropTypes.object,
};

export default Legend;
