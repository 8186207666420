export const getPollutantInfo = (pollutant, config) => {
  const value = pollutant.aqipollutantvalue
    ? pollutant.aqipollutantvalue
    : pollutant.pollutantvalue;

  const o3 = { ...config.aqi.o3 };
  const pm10 = { ...config.aqi.pm10 };
  const so2 = { ...config.aqi.so2 };
  const pm25 = { ...config.aqi.pm25 };
  const no2 = { ...config.aqi.no2 };
  switch (pollutant.pollutantnotation) {
    //Ozone (air)
    case 'O3':
      if (value < o3.good.max + 1) {
        return { level: 1, color: '#00FF00', text: 'Good' };
      }
      if (value >= o3.fair.min && value < o3.fair.max + 1) {
        return { level: 2, color: '#FFFF00', text: 'Fair' };
      }
      if (value >= o3.moderate.min && value < o3.moderate.max + 1) {
        return { level: 3, color: '#FFA500', text: 'Moderate' };
      }
      if (value >= o3.poor.min && value < o3.poor.max + 1) {
        return { level: 4, color: '#FF0000', text: 'Poor' };
      }
      if (value >= o3.veryPoor.min && value < o3.veryPoor.max + 1) {
        return { level: 5, color: '#800080', text: 'Very poor' };
      }
      if (value >= o3.extremelyPoor.min) {
        return { level: 6, color: '#800000', text: 'Extremley poor' };
      }
      break;
    //Particulate matter < 10 \u00b5m (aerosol)
    case 'PM10':
      if (value < pm10.good.max + 1) {
        return { level: 1, color: '#00FF00', text: 'Good' };
      }
      if (value >= pm10.fair.min && value < pm10.fair.max + 1) {
        return { level: 2, color: '#FFFF00', text: 'Fair' };
      }
      if (value >= pm10.moderate.min && value < pm10.moderate.max + 1) {
        return { level: 3, color: '#FFA500', text: 'Moderate' };
      }
      if (value >= pm10.poor.min && value < pm10.poor.max + 1) {
        return { level: 4, color: '#FF0000', text: 'Poor' };
      }
      if (value >= pm10.veryPoor.min && value < pm10.veryPoor.max + 1) {
        return { level: 5, color: '#800080', text: 'Very poor' };
      }
      if (value >= pm10.extremelyPoor.min) {
        return { level: 6, color: '#800000', text: 'Extremley poor' };
      }
      break;
    //Sulphur dioxide (air)
    case 'SO2':
      if (value < so2.good.max + 1) {
        return { level: 1, color: '#00FF00', text: 'Good' };
      }
      if (value >= so2.fair.min && value < so2.fair.max + 1) {
        return { level: 2, color: '#FFFF00', text: 'Fair' };
      }
      if (value >= so2.moderate.min && value < so2.moderate.max + 1) {
        return { level: 3, color: '#FFA500', text: 'Moderate' };
      }
      if (value >= so2.poor.min && value < so2.poor.max + 1) {
        return { level: 4, color: '#FF0000', text: 'Poor' };
      }
      if (value >= so2.veryPoor.min && value < so2.veryPoor.max + 1) {
        return { level: 5, color: '#800080', text: 'Very poor' };
      }
      if (value >= so2.extremelyPoor.min) {
        return { level: 6, color: '#800000', text: 'Extremley poor' };
      }
      break;
    //Particulate matter < 2.5 \u00b5m (aerosol)
    case 'PM2.5':
      if (value < pm25.good.max + 1) {
        return { level: 1, color: '#00FF00', text: 'Good' };
      }
      if (value >= pm25.fair.min && value < pm25.fair.max + 1) {
        return { level: 2, color: '#FFFF00', text: 'Fair' };
      }
      if (value >= pm25.moderate.min && value < pm25.moderate.max + 1) {
        return { level: 3, color: '#FFA500', text: 'Moderate' };
      }
      if (value >= pm25.poor.min && value < pm25.poor.max + 1) {
        return { level: 4, color: '#FF0000', text: 'Poor' };
      }
      if (value >= pm25.veryPoor.min && value < pm25.veryPoor.max + 1) {
        return { level: 5, color: '#800080', text: 'Very poor' };
      }
      if (value >= pm25.extremelyPoor.min) {
        return { level: 6, color: '#800000', text: 'Extremely poor' };
      }
      break;
    //Nitrogen dioxide (air)
    case 'NO2':
      if (value < no2.good.max + 1) {
        return { level: 1, color: '#00FF00', text: 'Good' };
      }
      if (value >= no2.fair.min && value < no2.fair.max + 1) {
        return { level: 2, color: '#FFFF00', text: 'Fair' };
      }
      if (value >= no2.moderate.min && value < no2.moderate.max + 1) {
        return { level: 3, color: '#FFA500', text: 'Moderate' };
      }
      if (value >= no2.poor.min && value < no2.poor.max + 1) {
        return { level: 4, color: '#FF0000', text: 'Poor' };
      }
      if (value >= no2.veryPoor.min && value < no2.veryPoor.max + 1) {
        return { level: 5, color: '#800080', text: 'Very poor' };
      }
      if (value >= no2.extremelyPoor.min) {
        return { level: 6, color: '#800000', text: 'Extremely poor' };
      }
      break;
    default:
      return { level: 0, color: '#000000', text: 'None' };
  }
};

export const sortPollutants = (station) => {
  const sorting = ['NO2', 'PM10', 'PM2.5', 'O3', 'SO2'];
  const sortedPollutants = [];
  sorting.forEach((key) => {
    let found = false;
    station.pollutants = station.pollutants.filter((pollutant) => {
      if (!found && pollutant.pollutantnotation === key) {
        sortedPollutants.push(pollutant);
        found = true;
        return false;
      } else return true;
    });
  });
  station.pollutants = sortedPollutants;
};

export const getPollutantDisplay = (notation) => {
  switch (notation) {
    case 'NO2':
      return 'NO₂';
    case 'SO2':
      return 'SO₂';
    case 'O3':
      return 'O₃';
    default:
      return notation;
  }
};
