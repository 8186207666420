import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Control from 'react-leaflet-control';
import 'react-dropdown/style.css';
import { SELECTED_POLLUTANT } from '../../utils/enums';
import Button from '@material-ui/core/Button';
import { materialButtonStyle } from '../../utils/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import MenuItem from '../AirMap/SideMenu/MenuItem';
import '../AirMap/SideMenu/SideMenu.css';
import FocusTrap from 'focus-trap-react';
import { useTranslation } from 'react-i18next';
import { getPollutantDisplay } from '../../utils/funcs';

const PollutantSelector = ({ setSelectedPollutant, selectedPollutant }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const pollutants = Object.keys(SELECTED_POLLUTANT);

  const changePollutant = (pollutant) => () => {
    setSelectedPollutant(pollutant);
    setOpen(false);
  };

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <Control className="selector-position raven-side-menu" position="topleft">
        <div style={{ display: 'flex' }}>
          <Button
            variant="contained"
            color="primary"
            title="Meny"
            onClick={toggleOpen}
            style={{
              ...materialButtonStyle,
            }}
          >
            {t('menu-show')}
            {!open ? (
              <ArrowDropDownIcon className="menu-button-icon" />
            ) : (
              <ArrowDropUpIcon className="menu-button-icon" />
            )}
          </Button>
          <Button
            className="not-allowed"
            variant="contained"
            color="primary"
            title="Meny"
            style={{
              borderLeft: '1px solid #006cc0',
              ...materialButtonStyle,
            }}
          >
            {getPollutantDisplay(
              selectedPollutant === 'AQI' ? t('aqi') : selectedPollutant
            )}
          </Button>
        </div>
        {open && (
          <>
            <FocusTrap
              focusTrapOptions={{
                clickOutsideDeactivates: true,
                allowOutsideClick: true,
              }}
            >
              <div className="side-menu--list">
                {pollutants.map((pollutant, i) => {
                  return (
                    <MenuItem
                      key={i}
                      divider={pollutants.length === i + 1}
                      text={getPollutantDisplay(
                        pollutant === 'AQI' ? t('aqi') : pollutant
                      )}
                      onClick={changePollutant(pollutant)}
                      selected={pollutant === selectedPollutant}
                      style={{ backgroundColor: 'white' }}
                    />
                  );
                })}
              </div>
            </FocusTrap>
          </>
        )}
      </Control>
    </>
  );
};

PollutantSelector.propTypes = {
  setSelectedPollutant: PropTypes.func,
  selectedPollutant: PropTypes.string,
};

export default PollutantSelector;
