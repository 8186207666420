const ERROR_MESSAGE = 'Error when fetching stations.';

const END_POINT = 'https://aqms.live/kvalitetzraka/stationspollutants.php';

export const fetchStations = async () => {
  try {
    const response = await fetch(`${END_POINT}`);

    const json = await response.json();
    if (!response.ok) {
      return {
        error: ERROR_MESSAGE,
      };
    }
    return json;
  } catch (e) {
    return { error: ERROR_MESSAGE };
  }
};
