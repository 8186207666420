import React, { useEffect, useState } from 'react';
import { fetchContacts } from '../../api/contacts';
import Error from '../Error';
import Loading from '../Loading';
import ContactItem from './ContactItem';
import './Contact.css';

const Contact = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [contacts, setContacts] = useState(null);

  useEffect(() => {
    const initData = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetchContacts();
        if (!response || response.error) {
          setError('Fetching contacts failed...');
          setLoading(false);
        }
        if (response && response[0]) {
          setContacts(response);
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
      }
    };
    initData();
  }, []);
  return (
    <div>
      <Loading isLoading={loading} text="Loading data..." />
      <Error error={error} />
      {!error &&
        !loading &&
        contacts &&
        contacts.length > 0 &&
        contacts.map((contact, i) => {
          return (
            <>
              <div id="raven-contact" key={`contact${i}`}>
                <ContactItem contact={contact} />
              </div>
              <br />
            </>
          );
        })}
    </div>
  );
};

export default Contact;
